<h1 class="site-logo">
  <svg
    aria-labelledby="logo-title"
    width="420"
    height="35"
    viewBox="0 0 420 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="logo-title">Anagrammana</title>
    <path
      d="M0 33.6975L11.5518 0.49275H13.7691L25.2662 33.6975H23.1311L20.2568 27.1277L12.6194 30.4126L4.92733 27.1277L2.08043 33.6975H0ZM5.47481 25.1841L12.6194 27.9215L19.7093 25.1841L12.6194 3.12066L5.47481 25.1841Z"
      fill="white"
    />
    <path
      d="M37.8562 0.492733H40.9494L59.7554 31.836H59.4817V0.492733H61.5073V33.6975H58.6057L39.6355 2.05305H39.8818V33.6975H37.8562V0.492733Z"
      fill="white"
    />
    <path
      d="M124.241 34.2449C121.667 34.2449 119.304 33.588 117.151 32.274C114.997 30.9601 113.273 29.0712 111.977 26.6076C110.7 24.1257 110.061 21.151 110.061 17.6836C110.061 14.636 110.49 11.999 111.347 9.77254C112.223 7.54611 113.391 5.71205 114.851 4.27035C116.329 2.82865 117.99 1.76106 119.833 1.06759C121.695 0.355863 123.602 0 125.554 0C127.58 0 129.478 0.383237 131.248 1.14971C133.037 1.91618 134.752 3.12064 136.395 4.76308L134.999 6.07704C133.447 4.56234 131.878 3.48563 130.29 2.8469C128.721 2.18992 127.142 1.86143 125.554 1.86143C123.182 1.86143 120.974 2.50016 118.93 3.77762C116.886 5.03682 115.244 6.85264 114.003 9.22506C112.762 11.5792 112.141 14.3988 112.141 17.6836C112.141 20.4575 112.616 22.9486 113.565 25.1568C114.514 27.3649 115.891 29.1169 117.698 30.4126C119.505 31.69 121.677 32.3288 124.213 32.3288C126.02 32.3288 127.635 31.909 129.058 31.0695C130.5 30.2118 131.632 28.8705 132.453 27.0456C133.292 25.2206 133.712 22.8573 133.712 19.9557H134.916C134.916 22.8391 134.57 25.3575 133.876 27.5109C133.183 29.6461 132.051 31.3068 130.482 32.493C128.931 33.661 126.85 34.2449 124.241 34.2449ZM134.314 33.6975L133.712 25.321V19.9557L124.377 19.5724V17.9026H135.792V33.6975H134.314Z"
      fill="white"
    />
    <path
      d="M151.908 33.6975V0.492733H162.72C166.315 0.492733 168.989 1.37783 170.741 3.14802C172.493 4.89995 173.369 7.16288 173.369 9.93678C173.369 12.7289 172.493 15.0101 170.741 16.7803C168.989 18.5322 166.315 19.4082 162.72 19.4082H153.933V33.6975H151.908ZM171.234 33.6975L160.941 19.1618H163.295L173.807 33.6975H171.234ZM153.933 17.7384H162.748C165.522 17.7384 167.639 17.0175 169.098 15.5758C170.558 14.1159 171.288 12.218 171.288 9.88203C171.288 7.49137 170.549 5.61168 169.071 4.24298C167.611 2.87428 165.494 2.18992 162.72 2.18992H153.933V17.7384Z"
      fill="white"
    />
    <path
      d="M223.219 0.492733H226.175L239.835 26.3338H239.507L253.194 0.492733H256.123V33.6975H254.042V1.83406H254.59L240.437 28.9344H238.987L224.889 2.05305H225.245V33.6975H223.219V0.492733Z"
      fill="white"
    />
    <path
      d="M273.551 0.492733H276.507L290.167 26.3338H289.839L303.526 0.492733H306.455V33.6975H304.374V1.83406H304.922L290.769 28.9344H289.318L275.221 2.05305H275.577V33.6975H273.551V0.492733Z"
      fill="white"
    />
    <path
      d="M357.961 0.492733H361.055L379.861 31.836H379.587V0.492733H381.613V33.6975H378.711L359.741 2.05305H359.987V33.6975H357.961V0.492733Z"
      fill="white"
    />
    <path
      d="M74.3753 33.6975L85.9271 0.492736H88.1444L99.6415 33.6975H97.5064L94.6321 27.1277L86.9947 30.4126L79.3026 27.1277L76.4557 33.6975H74.3753ZM79.8501 25.1841L86.9947 27.9215L94.0846 25.1841L86.9947 3.12064L79.8501 25.1841Z"
      fill="white"
    />
    <path
      d="M184.501 33.6975L196.053 0.49273H198.27L209.767 33.6975H207.632L204.758 27.1277L197.121 30.4125L189.428 27.1277L186.581 33.6975H184.501ZM189.976 25.1841L197.121 27.9215L204.21 25.1841L197.121 3.12064L189.976 25.1841Z"
      fill="white"
    />
    <path
      d="M319.647 33.6975L331.199 0.492734H333.416L344.913 33.6975H342.778L339.904 27.1277L332.266 30.4126L324.574 27.1277L321.727 33.6975H319.647ZM325.122 25.1841L332.266 27.9215L339.356 25.1841L332.266 3.12064L325.122 25.1841Z"
      fill="white"
    />
    <path
      d="M394.734 33.6975L406.286 0.492734H408.503L420 33.6975H417.865L414.991 27.1277L407.353 30.4126L399.661 27.1277L396.814 33.6975H394.734ZM400.209 25.1841L407.353 27.9215L414.443 25.1841L407.353 3.12064L400.209 25.1841Z"
      fill="white"
    />
  </svg>
</h1>

<style>
  .site-logo {
    width: 420px;
    max-width: 100%;
    margin: 0 auto;
    line-height: 0;
  }
  .site-logo > svg {
    width: 420px;
    max-width: 100%;
  }
</style>
