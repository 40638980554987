export const nonAlphanumeric = /[^a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ0-9]/gi;
export const specialChars = /[^ a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ0-9]/gi;
export const alphaSort = (a, b) => a.localeCompare(b);
export const lengthSort = (a, b) => b.length - a.length;
export const difference = (arr1, arr2) => arr1.filter((item) => !arr2.includes(item));
export const verseToIds = (verse) => {
    const processed = verse
        .toLowerCase()
        .replace(nonAlphanumeric, "")
        .split("")
        .sort()
        .join("");
    return processed
        .split("")
        .map((char, idx) => char + processed.substring(0, idx).split(char).length);
};
export const checkLetters = (seedIds, inputText) => {
    const inputIds = verseToIds(inputText);
    const missing = difference(seedIds, inputIds);
    const extra = difference(inputIds, seedIds);
    return [missing, extra];
};
export const textToWords = (text) => [
    ...new Set(text
        .replace(/\s+/g, " ")
        .replace(specialChars, "")
        .toLowerCase()
        .split(" ")
        .filter(Boolean)),
];
// Missing letters might be like ['a3', 'a4', 't2] so this changes them to ['a1', 'a2', 't1']
const reId = (ids) => verseToIds(ids.map((item) => item.split("")[0]).join(""));
export const hasNoExtraLetters = (seedIds, inputText) => {
    return !checkLetters(reId(seedIds), inputText)[1].length;
};
export const findPossibleWords = (seedIds, text) => {
    const words = [...new Set(textToWords(text))];
    return words.filter((item) => hasNoExtraLetters(seedIds, item));
};
